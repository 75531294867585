import './NavLinks.css'

const NavLinks = ({ navLinks, handleScroll }) => {
    return (
        <ul className="nav-links self-center flex justify-center gap-2">
            {navLinks.map(navLink => (
                <li className="nav-link px-4 py-1 z-40 rounded-full block" key={navLink.name}>
                    <a className='block'  onClick={() => {
                handleScroll(`${navLink.name.toLowerCase()}`);
              }} >{navLink.name}</a>
                </li>
            ))}
        </ul>
    )
}

export default NavLinks;
