import AboutSection1 from "./Section1/Section1"
import AboutSection2 from "./Section2/Section2"
import AboutSection3 from "./Section3/Section3"

const About = () => {
    
    return (

        <section className="about flex flex-col gap-24 md:gap-80">
            <AboutSection1/>
            <AboutSection2/>
            <AboutSection3/>
        </section>
    )
}

export default About