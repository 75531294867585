import { useEffect } from "react";
import "./Hero.css";
import HeroGIF from "../../assets/images/hero-gif.gif";
import Container from "../UI/Container/Container";
import frame from "../../assets/images/frame 85pcent.png";
import ScrollAnimation from "react-animate-on-scroll";

const Hero = () => {

  return (
    <div className="container mx-auto w-screen relative">

      <div className="hero h-auto md:h-screen flex flex-col mb-24 md:mb-0 md:flex-row justify-center md:justify-evenly items-center gap-0 md:gap-6">
        <div className="hero-text flex flex-col gap-6 text-left order-2 md:order-1">
          <span className="hero-text__sub-header tracking-widest">
            When Future Stops, NFT talks...
          </span>
          <h1 className="hero-text__header leading-tight text-2xl md:text-5xl">
            ALL YOUR KNOWLEDGE ONE MINT AWAY
          </h1>
          <p className="hero-text__paragraph text-lg md:text-2xl">
            FIND YOUR PLACE IN THE NFT SPACE
          </p>
          <button onClick={() => { window.location.href = 'https://mint.thenftist.com' }} className="w-32 px-4 py-2 text-black font-bold bg-gradient-to-r from-[#c2fb38] to-purple-900/50 rounded transform transition duration-300 hover:scale-110">
            MINT
          </button>
        </div>
        <div className="hero-image order-1 md:order-2">
          <div className="hero-image__frame relative">
            <img className="w-[50rem]" src={frame} />
            <div className="hero-image__img absolute">
              <img className="w-full" src={HeroGIF} alt="nftist gif" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
