import Title from "../../UI/Title/Title";
import Container from "../../UI/Container/Container";
import SectionImage from '../../../assets/images/Decentralised media trust v2.png';

const Section2 = () => {
    return (
        <div className="">
            <Title
                title="DECENTRALISED MEDIA TRUST"
                backgroundTitle="MEDIA TRUST"
            />
            <Container>
                <img className="mt-20 md:mt-40" src={SectionImage} alt="about"/>
                <div className="about-text mt-12">
                    <p className="text-2xl text-center">Our vision is to become the standard in the NFT industry as the first decentralized media trust, providing our community with the highest reputable information in WEB3.</p>
                </div>
            </Container>
        </div>
    )
}

export default Section2;