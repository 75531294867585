import React from "react";
import Title from "../UI/Title/Title";
import { ReactComponent as LoginKey } from "../../assets/logo/login-key.svg";
import { ReactComponent as UnlockPriviliges } from "../../assets/logo/unlock-priviledges.svg";
import { ReactComponent as EnjoyCommunity } from "../../assets/logo/enjoy-community.svg";
import l from "../../assets/images/Loginkey.png";
import u from "../../assets/images/Unlock Privileges.png";
import e from "../../assets/images/Enjoy the community.png";
import "./Benefits.css";
import ScrollAnimation from "react-animate-on-scroll";

function Benefits() {
  return (
    <section id="benefits" className="flex flex-col gap-8 mt-36">
      <Title title="BENEFITS" backgroundTitle="BENEFITS" />
      <div className="grid grid-cols-1 md:grid-cols-3 justify-items-stretch items-center w-full mt-12 md:mt-52 mb-40">
        <ScrollAnimation className="flex justify-center" animateIn="fadeInRight" delay={0} animateOnce={true}>
          <div className="benefit green-glow flex flex-col justify-center">
            <div className="w-full flex justify-center h-60">
              <img className="w-60 h-60 object-contain" src={l} />
            </div>
            <h1 className="mt-10 text-center font-semibold text-white text-3xl mx-auto whitespace-nowrap">
              Log in key
            </h1>
          </div>
        </ScrollAnimation>
        <ScrollAnimation className="flex justify-center" animateIn="fadeInRight " delay={100} animateOnce={true}>
          <div className="benefit pink-glow flex flex-col justify-center">
            <div className="w-full flex justify-center h-60">
              <img className="w-60 h-60 object-contain" src={u} />
            </div>
            <h1 className="mt-10 text-center font-semibold text-white text-3xl mx-auto whitespace-nowrap">
              Unlock benefits
            </h1>
          </div>
        </ScrollAnimation>
        <ScrollAnimation className="flex justify-center" animateIn="fadeInRight" delay={200} animateOnce={true}>
          <div className="benefit green-glow flex flex-col justify-center">
            <div className="w-full flex justify-center h-60">
              <img className="w-60 h-60 object-contain" src={e} />
            </div>
            <h1 className="mt-10 text-center font-semibold text-white text-3xl mx-auto whitespace-nowrap">
              Enjoy the community
            </h1>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  );
}

export default Benefits;
