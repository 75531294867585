import React from "react";
import popup from "../../assets/images/Popup.png";
import cross from "../../assets/images/Exit cross.png";

function Modal({ data }) {
  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-black bg-opacity-90 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>

        <div className="inline-block align-bottom bg-transparent rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full lg:w-1/2 relative p-4">
          <button className="p-1 absolute top-4 right-0" onClick={()=>{data?.setshow(false);}}>
            <img className=" h-6 w-6" src={cross} />
          </button>
          <img className="" src={popup} alt="popup" />
          
        </div>
      </div>
    </div>
  );
}

export default Modal;
