import React from 'react'
import Title from '../UI/Title/Title'
import u from "../../assets/images/team members.png";
import v from "../../assets/images/Team mobile.png";
import ScrollAnimation from "react-animate-on-scroll";

function Team() {
    return (
        <section id='team' className="flex flex-col gap-8 mb-20 md:my-20" >
            <div className="mb-12 md:mb-32">
                <Title
                    title="TEAM"
                    backgroundTitle="team"
                />
            </div>
            <ScrollAnimation animateIn="fadeIn">
            <img className='w-0 md:w-10/12 invisible md:visible mx-auto' src={u} alt='team members'/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
            <img className='md:w-0 w-10/12 visible md:invisible mx-auto' src={v} alt='team members'/>
            </ScrollAnimation>
        </section>
    )
}

export default Team