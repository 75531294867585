import {useRef} from 'react';
import Title from "../../UI/Title/Title";
import { ReactComponent as SectionImage } from '../../../assets/images/about1.svg';
import './Section1.css';

const AboutSection1 = (props) => {
    const ref = useRef();


    return (
        <div ref={ref} className="about flex items-center gap-0 md:gap-8">
            <div className="about-img w-1/12 md:w-2/5 relative z-0">
                <SectionImage className="absolute" />
            </div>
            <div className="about-text basis-4/5 md:basis-2/5 z-10 flex flex-col justify-center">
                <Title
                    title="STAY AHEAD OF THE GAME"
                    backgroundTitle="STAY AHEAD"
                />
                <p className="mt-8 text-xl md:text-left text-center">Have you ever imagined what it would be like to have a single trusted place, that provides you with everything you need, leading to successful decisions when it comes to being properly informed about everything related to the NFT industry.</p>
                <p className="mt-4 text-xl md:text-left text-center">THE NFTiST artwork represents the love we have for different areas and knowledge is the only best way we know to access the path of freedom and achievement.</p>
            </div>
        </div>
    )
}

export default AboutSection1;
