import React from "react";
import Title from "../UI/Title/Title";
import j from "../../assets/images/circle.png";
import a from "../../assets/images/Curated content Platform.png";
import b from "../../assets/images/City of the NFTist.png";
import c from "../../assets/images/Live and Digital Events.png";
import d from "../../assets/images/The NFTist Magazine.png";
import ScrollAnimation from "react-animate-on-scroll";
import k from "../../assets/images/Ecosystem Mobile.png";

function Ecosystem() {
  return (
    <section id="ecosystem" className="flex flex-col gap-8 ">
      <div className="mb-16 md:mb-24">
        <Title title="THE ECOSYSTEM INCLUDES" backgroundTitle="ECOSYSTEM" />
      </div>
      <div className="h-0 md:h-auto w-0 md:w-auto invisible md:visible flex flex-col gap-32 text-xl mb-0 md:mb-96 relative">
        <div className="flex justify-center z-10">
          <ScrollAnimation animateIn="fadeInLeft" delay={0} animateOnce={true}>
            <div className="flex flex-col justify-center items-center mx-auto">
              <img src={a} alt="" className="w-auto mb-14" />
              <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
                Curated Content Platform
              </h1>
              <p className="text-white text-justify w-1/2 mx-auto">
                {" "}
                Our platform was designed as a coaching tool, providing in-depth
                articles, daily news, analysis, strategies, access to verified
                NFT projects and podcasts featuring entrepreneurs and
                consecrated names of the niche.
              </p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInRight" delay={0} animateOnce={true}>
            <div className="flex flex-col justify-center items-center mx-auto">
              <img src={b} alt="" className="w-auto mb-14" />
              <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
                City of The NFTiST
              </h1>
              <p className="text-white text-justify w-1/2 mx-auto ">
                {" "}
                We are creating a new Metaverse city accessible only to our
                members. All kind of activities will be possible here, such as
                networking, parties, meetings, business opportunities and many
                more.
              </p>
            </div>
          </ScrollAnimation>
        </div>

        <div className="flex justify-center z-10">
          <ScrollAnimation className="flex basis-1/2 flex-col justify-center items-center mx-auto" animateIn="fadeInLeft" delay={0} animateOnce={true}>
            <div className="flex flex-col justify-center items-center mx-auto">
              <img src={c} alt="" className="w-auto mb-14" />
              <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
                Live & Digital Events
              </h1>
              <p className="text-white text-justify w-1/2 mx-auto">
                {" "}
                Being part of our ecosystem will grant you the chance to meet
                high profile influencers, celebrities and leaders of the NFT
                industry. Our live events will take place in London, Dubai and
                the USA.
              </p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="flex basis-1/2 flex-col justify-center items-center mx-auto" animateIn="fadeInRight" delay={0} animateOnce={true}>
            <div className="flex flex-col justify-center items-center mx-auto">
              <img src={d} alt="" className="w-auto mb-14" />
              <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
                The NFTiST Magazine
              </h1>
              <p className="text-white text-justify w-1/2 mx-auto">
                The print & digital magazine will provide exclusive knowledge, curated content, access to global NFT events (qr codes), and other perks.
              </p>
            </div>
          </ScrollAnimation>
        </div>
        <img
          className="absolute w-full z-0 top-0 left-1/2"
          style={{ transform: "translate(-50%, 18%)" }}
          src={j}
          alt=""
        />
      </div>
      <div className="w-auto md:w-0 md:invisible visible flex flex-col gap-12 text-xl mb-96 md:mb-0 lg:mb-44 relative h-auto md:h-0">

        <ScrollAnimation animateIn="fadeInLeft" delay={0} animateOnce={true}>
          <div className="flex flex-col justify-center items-center mx-auto">
            <img src={a} alt="" className="w-1/4 mb-4" />
            <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
              Curated Content Platform
            </h1>
            <p className="text-white text-center w-10/12 mx-auto">
              {" "}
              Our platform was designed as a coaching tool, providing in-depth
              articles, daily news, analysis, strategies, access to verified
              NFT projects and podcasts featuring entrepreneurs and
              consecrated names of the niche.
            </p>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" delay={0} animateOnce={true}>
          <div className="flex flex-col justify-center items-center mx-auto">
            <img src={b} alt="" className="w-1/4 mb-4" />
            <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
              City of The NFTiST
            </h1>
            <p className="text-white text-center w-10/12 mx-auto ">
              {" "}
              We are creating a new Metaverse city accessible only to our
              members. All kind of activities will be possible here, such as
              networking, parties, meetings, business opportunities and many
              more.
            </p>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0} animateOnce={true}>
          <div className="flex basis-1/2 flex-col justify-center items-center mx-auto">
            <img src={c} alt="" className="w-1/4 mb-4" />
            <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
              Live & Digital Events
            </h1>
            <p className="text-white text-center w-10/12 mx-auto">
              {" "}
              Being part of our ecosystem will grant you the chance to meet
              high profile influencers, celebrities and leaders of the NFT
              industry. Our live events will take place in London, Dubai and
              the USA.
            </p>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" delay={0} animateOnce={true}>
          <div className="flex basis-1/2 flex-col justify-center items-center mx-auto">
            <img src={d} alt="" className="w-1/4 mb-4" />
            <h1 style={{ color: "#affe00" }} className="font-bold mb-4">
              The NFTiST Magazine
            </h1>
            <p className="text-white text-center w-10/12 mx-auto">
              The print & digital magazine will provide exclusive knowledge, curated content, access to global NFT events (qr codes), and other perks.
            </p>
          </div>
        </ScrollAnimation>
        <img
          className="absolute w-full z-0 -top-80 h-[130rem] object-cover opacity-30"
          src={k}
          alt=""
        />
      </div>
    </section>
  );
}

export default Ecosystem;
