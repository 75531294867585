import './NavButtons.css'

const NavButtons = ({ navButtons }) => {
    return (
        <div className="navbar__buttons flex justify-end z-40">
            {navButtons.map((button,i) => (
                <button className="navbar__button border-2 rounded-full" key={i} onClick={()=>window.location.href = 'https://nftist-s3.s3.amazonaws.com/Litepaper+PDF/NFTist%2BLitepaper.pdf'}>
                    <span className="navbar__button-text px-8 py-4 tracking-widest">
                        {button.name}
                    </span>
                </button>
            ))}
        </div>
    )
}

export default NavButtons;
