import { ReactComponent as ArrowRight } from "../../../../assets/logo/arrow-fill-right.svg";
import './FooterLink.css'

const FooterLink = ({ text, handleScroll }) => {
    return (
        <li className="footer-link flex items-center gap-4">
            <a onClick={()=>{handleScroll(text.toLowerCase())}} className="text-lg hover:cursor-pointer">{text}</a>
            <ArrowRight/>
        </li>
    )
}

export default FooterLink;