import p from "../../assets/images/1.png";
import q from "../../assets/images/2.png";
import r from "../../assets/images/3.png";
import s from "../../assets/images/4.png";
import t from "../../assets/images/5.png";
import u from "../../assets/images/6.png";
import ScrollAnimation from "react-animate-on-scroll";

const NFTBanner = () => {
  return (
    <section
      id="nft-banner"
      className="grid gap-4 grid-cols-1 grid-rows-4 md:grid-cols-3 md:grid-rows-2 gap-y-16 justify-items-center w-10/12 mx-auto my-10 md:mb-20 md:mt-0"
    >
      <ScrollAnimation animateIn="flipInX" delay={0}>
        <img src={p} alt="" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="flipInX" delay={50}>

      <img src={q} alt="" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="flipInX" delay={100}>
      <img src={r} alt="" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="flipInX" delay={150}>
      <img src={s} alt="" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="flipInX" delay={200}>
      <img src={t} alt="" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="flipInX" delay={250}>
      <img src={u} alt="" />
      </ScrollAnimation>
    </section>
  );
};

export default NFTBanner;
