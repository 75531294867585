import { useState, useEffect, useRef } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Benefits from "./components/Benefits/Benefits";
import Ecosystem from "./components/Ecosystem/Ecosystem";
import NFTBanner from "./components/NFTBanner/NFTBanner";
import Roadmap from "./components/Roadmap/Roadmap";
import Team from "./components/Team/Team";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";
import Faq from "./components/Faq/Faq";

const scrollToRef = (ref) => window.scrollTo({behavior:'smooth',top: ref.current.offsetTop});

function App() {
  const [show, setshow] = useState(true);
  const faq = useRef();
  const abstract = useRef();
  const collection = useRef();
  const benefits = useRef();
  const ecosystem = useRef();
  const roadmap = useRef();
  const team = useRef();

  const handleScroll = (refType) => {
    switch (refType) {
      case "abstract":
        scrollToRef(abstract);
        break;
      case "collection":
        scrollToRef(collection);
        break;
      case "benefits":
        scrollToRef(benefits);
        break;
      case "ecosystem":
        scrollToRef(ecosystem);
        break;
      case "roadmap":
        scrollToRef(roadmap);
        break;
      case "team":
        scrollToRef(team);
        break;
      case "faq":
        scrollToRef(faq);
        break;
    }
  };
  return (
    <>
      <div className="w-screen overflow-x-clip">
        {show && <Modal data={{ setshow }} />}
        <Navbar handleScroll={handleScroll}>
          <div className="App">
            <Hero />
            <div ref={abstract}>
              <About />
            </div>
            <div ref={benefits}>
              <Benefits />
            </div>
            <div ref={ecosystem}>
              <Ecosystem />
            </div>
            <div ref={collection}>
              <NFTBanner />
            </div>
            <div ref={roadmap}>
              <Roadmap />
            </div>
            <div ref={team}>
              <Team />
            </div>
            <div ref={faq}>
              <Faq />
            </div>
            <Footer handleScroll={handleScroll}/>
          </div>
        </Navbar>
      </div>
    </>
  );
}

export default App;
