import React, { useState } from "react";
import Title from "../UI/Title/Title";

function Faq() {
  const [faq, setfaq] = useState(-1);
  return (
    <section id="team" className="flex flex-col gap-8 mb-20 md:my-20">
      <div className="mb-12 md:mb-32">
        <Title title="FAQS" backgroundTitle="faqs" />
      </div>
      <div className="flex flex-col w-full items-center">
        <div className="md:w-10/12 w-11/12 border border-dashed border-white">
          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 0)
                setfaq(-1)
              else
                setfaq(0)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              What is the NFTist?
            </h3>
          </button>
          <div
            className={`${faq == 0 ? "opacity-100 h-[35rem] md:h-[12rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4 " style={{ color: "#b2fd00" }}>
              The NFTiST is an inclusive community for like-minded people, building our way together towards the
              technology of tomorrow by gathering - NFTs
              Our collection of manually designed digital art will grant you access to the highest reputable
              informational system in Web3! Our purpose is to offer you the much-needed guidance in order to make
              profitable decisions – the platform was designed as a coaching tool, providing in-depth articles, daily
              news, analysis, strategies, access to verified NFT projects, and podcasts featuring entrepreneurs and
              consecrated names of the niche.
            </h3>
          </div>

          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 1)
                setfaq(-1)
              else
                setfaq(1)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              What is minting?
            </h3>
          </button>

          <div
            className={`${faq == 1 ? "opacity-100 h-[8-rem] md:h-[6rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              Minting basically refers to the process of turning digital art
              into a part of the Ethereum Blockchain
            </h3>
          </div>

          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 2)
                setfaq(-1)
              else
                setfaq(2)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              How many will be minted?
            </h3>
          </button>

          <div
            className={`${faq == 2 ? "opacity-100 h-[6rem] md:h-[5rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              There will only ever be 10,000 NFTiSTs.
            </h3>
          </div>

          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 11)
                setfaq(-1)
              else
                setfaq(11)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              How can I buy the NFTist?
            </h3>
          </button>

          <div
            className={`${faq == 11 ? "opacity-100 h-[13rem] md:h-[7rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              You will be able to mint the NFTs directly on our website, www.thenftist.com . If this is your first
              experience buying an NFT, check out our #how-to-buy-section.
            </h3>
          </div>

          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 4)
                setfaq(-1)
              else
                setfaq(4)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              How much will cost to mint a NFT?
            </h3>
          </button>
          <div
            className={`${faq == 4 ? "opacity-100 h-[9rem] md:h-[7rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              The price for THE NFTiST NFT is.0.17 eth + gas fee
            </h3>
          </div>
          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 5)
                setfaq(-1)
              else
                setfaq(5)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              What are the advantages of being a part of "The NFTist"?
            </h3>
          </button>
          <div
            className={`${faq == 5 ? "opacity-100 h-[50rem] md:h-[15rem] md:h-[15rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              THE NFTiST is maybe the most remarkable member’s club in the space, as it is based on the core of the
              space. Its art doubles as a membership card and provides access to a complete informational system,
              built around blockchain and NFT technology. Amongst the benefits, we will provide our members with
              LIVE & DIGITAL EVENTS (massive headliners in the NFT space), as well as the decentralised media trust
              platform, where (NFT Launchpad) exclusive seed phase projects will be listed ONLY there and nowhere
              else. You will also be able to find NEWS, DAILY DROP UPDATES, in-depth ARTICLES, STRATEGIES,
              ANALYSIS, and least but no last, PODCASTS WITH TOP LEADERS from within the industry. The most
              exciting part is a VR technology, to be implemented as a METAVERSE city, called “THE CITY OF THE
              NFTiST”.
            </h3>
          </div>

          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 9)
                setfaq(-1)
              else
                setfaq(9)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              How can I promote the project?
            </h3>
          </button>
          <div
            className={`${faq == 9 ? "opacity-100 h-[17rem] md:h-[9rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              The best way to promote the project is by inviting people to our
              DISCORD channel. If you don’t know how to invite people, check
              the #how-to-invite channel. If you are an influencer and would
              like to work with us, check the #influencers channel.
            </h3>
          </div>
          <button
            className="w-full flex justify-center py-2 border border-white"
            onClick={() => {
              if (faq == 10)
                setfaq(-1)
              else
                setfaq(10)
            }}
          >
            <h3 className="text-white text-lg font-semibold">
              What Blockchain protocol will you be using?
            </h3>
          </button>
          <div
            className={`${faq == 10 ? "opacity-100 h-[8rem] md:h-[5rem] visible py-2" : "opacity-0 h-0 invisible py-0"
              } transition-all ease-in-out duration-500 w-full flex justify-center border border-white`}
          >
            <h3 className="text-gray-300 text-lg text-center m-4" style={{ color: "#b2fd00" }}>
              Our NFTs will be living on the Ethereum platform – the biggest and easiest to use.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
