import FooterLink from './FooterLink/FooterLink';

const FooterLinks = ({ links, handleScroll }) => {
    return (
        <ul className="footer-links flex flex-col gap-4">
            {links.map(link => (
                <FooterLink key={link.name} text={link.name} handleScroll={handleScroll}/>
            ))}
        </ul>
    )
}

export default FooterLinks;
