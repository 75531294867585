import Title from "../../UI/Title/Title";
import SectionImage from '../../../assets/images/mEET THE NFTIS.png';

const Section2 = () => {
    return (
        <div className="about">
            <Title
                title="MEET THE NFTiST"
                backgroundTitle="the nftist"
            />
            <div className="w-10/12 md:w-1/2 mx-auto mt-20 about-text">
                <p className="text-2xl text-center">The artwork was <b>manually designed</b> to form a collection of <b>10000 NFTs,</b> living on the <b>Ethereum blockchain</b> and impersonated in the Metaverse.</p>
            </div>
            <img className="mt-10 md:mt-20 w-full" src={SectionImage} alt="about"/>
        </div>
    )
}

export default Section2;