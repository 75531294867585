import './Banner.css'

const Banner = () => {
    return (
        <div className='banner w-11/12 mx-auto p-12 flex flex-col md:flex-row justify-between rounded-lg'>
            <div className='banner__text text-left'>
                <h1 className='banner-title text-2xl font-bold text-center'>Join the NFTiST Community</h1>
                <p className='banner-text text-lg text-center'>Find your place in the NFT space</p>
            </div>
            <button className='mt-8 md:mt-0 create-account text-white rounded-xl px-8 py-2 font-semibold' onClick={() =>{window.location.href="https://discord.com/invite/thenftist"}}>JOIN DISCORD</button>
        </div>
    )
}

export default Banner;
