import './Title.css';

const Title = ({ title, backgroundTitle }) => {
    return (
        <div className="section-title relative">
            <div className="section-title__bg absolute whitespace-nowrap w-screen text-center">{backgroundTitle}</div>
            <h1 className="section-title__text text-center">{title}</h1>
        </div>
    );
}

export default Title;