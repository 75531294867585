import { ReactComponent as NFTSvg } from "../../assets/logo/nftist.svg";
import { ReactComponent as Twitter } from "../../assets/logo/twitter.svg";
import { ReactComponent as Instagram } from "../../assets/logo/instagram.svg";
import { ReactComponent as Discord } from "../../assets/logo/discord.svg";
import { ReactComponent as Telegram } from "../../assets/logo/telegram.svg";
import FooterLinks from "./FooterLinks/FooterLinks";
import './Footer.css'
import Banner from "../Banner/Banner";
import telegram from '../../assets/images/telegram.png';

const Footer = ({ handleScroll }) => {
  const footerLinksGrp1 = [
    {
      name: "ABSTRACT",
    },
    {
      name: "COLLECTION",
    },
    {
      name: "BENEFITS",
    },
    {
      name: "ECOSYSTEM",
    },

  ]
  const footerLinksGrp2 = [
    {
      name: "ROADMAP",
    },
    {
      name: "TEAM",
    },
    {
      name: "FAQ",
    },
  ]
  return (
    <section id="footer" className="mt-24">
      <Banner />
      <footer className="w-10/12 flex flex-col md:flex-row gap-16 md:gap-32 justify-between mx-auto px-8 py-16">
        <div className="logo basis-1/3">
          <NFTSvg />
          <p className="text-left mt-8 leading-tight">ALL YOUR KNOWLEDGE ONE MINT AWAY</p>
        </div>
        <div className="links basis-1/3">
          <h3 className="text-white text-left font-bold text-2xl">Useful Links</h3>
          <div className="flex flex-col md:flex-row gap-4 md:gap-20 mt-12">
            <FooterLinks links={footerLinksGrp1} handleScroll={handleScroll} />
            <FooterLinks links={footerLinksGrp2} handleScroll={handleScroll} />
          </div>
        </div>
        <div className="follow basis-1/3">
          <h3 className="text-white text-left font-bold text-2xl">Follow Us</h3>
          <p className="follow-text text-left leading-loose mt-8">And make sure you stay on top of the game with us!</p>
          <ul className="social-links flex justify-between mt-32">
            <li className="rounded-full p-2 hover:bg-white" onClick={() => { window.location.href = 'https://twitter.com/thenftist' }}><Twitter /></li>
            <li className="rounded-full p-2 hover:bg-white" onClick={() => { window.location.href = 'https://www.instagram.com/thenftist/' }}><Instagram /></li>
            <li className="rounded-full p-2 hover:bg-white" onClick={() => { window.location.href = 'https://discord.gg/yeuQjgESRW' }}><Discord /></li>
            <li className="rounded-full p-2 px-3 hover:bg-white" onClick={() => { window.location.href = 'https://t.me/thenftistchat' }}><img src={telegram} alt='' /></li>
          </ul>
        </div>
      </footer>
    </section>
  )
}

export default Footer;
