import React from 'react'
import Title from '../UI/Title/Title'
import bg from "../../assets/images/ROADMAP background.png";
import bg2 from "../../assets/images/roadmap timeline.png";
import bgg from "../../assets/images/ROADMAP background mobile.png";
import bgg2 from "../../assets/images/ROADMAP mobile.png";

function Roadmap() {
    return (
        <section id='roadmap' className="flex flex-col gap-8 mt-40" >
            <div className="z-10">
                <Title
                    title="ROADMAP"
                    backgroundTitle="Roadmap"
                />
            </div>
            <div className="h-0 md:h-auto my-0 md:my-12 relative invisible md:visible">
                <div className='absolute top-1/4 left-1/2 w-10/12 -translate-x-1/2 z-10 flex gap-60'>
                    <div className="-translate-y-8 translate-x-10 basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">1</span>PRESALE MINT</h1>
                        <p className="text-white mt-4">2000 NFTs will be available to mint at 0.16 ETH + gas fee</p>
                    </div>
                    <div className="-translate-y-24 -translate-x-2 basis-1/4" >
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">2</span>PUBLIC MINT</h1>
                        <p className="text-white mt-4">A number of NFTs will be available to mint at 0.17 ETH + gas fee</p>
                    </div>
                    <div className="-translate-y-44 -translate-x-16 basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">3</span>PLATFORM LAUNCH</h1>
                        <p className="text-white mt-4">Releasing the first Decentralised Media Trust platform. Owning THE NFTiST NFT will grant you access to our ecosystem, and help you be ahead of everyone in this industry</p>
                    </div>
                    <div className="-translate-y-24 -translate-x-44 basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">4</span>GALA EVENT</h1>
                        <p className="text-white mt-4">The first Gala event & party. The event will be held in London</p>
                    </div>
                </div>
                <div className='absolute bottom-1/4 left-1/2 w-10/12 -translate-x-1/2 z-10 flex gap-60'>
                    <div className="translate-y-72 translate-x-10 basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">5</span>MAGAZINE EDITION</h1>
                        <p className="text-white mt-4">The first print edition of the magazine distributed for our community and across UAE</p>
                    </div>
                    <div className="translate-y-64 -translate-x-10  basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">6</span>ERC20 RELEASE</h1>
                        <p className="text-white mt-4">10 million utility tokens will be airdropped for our NFTiST holders and general public</p>
                    </div>
                    <div className="translate-y-44 -translate-x-24 basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">7</span>DAO</h1>
                        <p className="text-white mt-4">Our media trust will launch a DAO where all the members will have a say</p>
                    </div>
                    <div className="translate-y-60 -translate-x-32 basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">8</span>METAVERSE</h1>
                        <p className="text-white mt-4">The City of THE NFTiST will be launched. See you in the Metaverse</p>
                    </div>
                </div>
                <img className="w-screen" src={bg} />
                <img className="w-screen absolute inset-0" style={{ transform: 'translateY(18%)' }} src={bg2} />
            </div>
            <div className="h-auto md:h-0 my-0 relative md:invisible visible relative h-[100rem]">
                <div className='absolute left-1/2 w-10/12 -translate-x-1/2 z-10 flex flex-col gap-10'>
                    <div className=" basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">1</span>PRESALE MINT</h1>
                        <p className="text-white mt-4">2000 NFTs will be available to mint at 0.16 ETH + gas fee</p>
                    </div>
                    <div className=" basis-1/4" >
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">2</span>PUBLIC MINT</h1>
                        <p className="text-white mt-4">A number of NFTs will be available to mint at 0.17 ETH + gas fee</p>
                    </div>
                    <div className="basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">3</span>PLATFORM LAUNCH</h1>
                        <p className="text-white mt-4">Releasing the first Decentralised Media Trust platform. Owning THE NFTiST NFT will grant you access to our ecosystem, and help you be ahead of everyone in this industry</p>
                    </div>
                    <div className=" basis-1/4">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">4</span>GALA EVENT</h1>
                        <p className="text-white mt-4">The first Gala event & party. The event will be held in London</p>
                    </div>
                    <div className="">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">5</span>MAGAZINE EDITION</h1>
                        <p className="text-white mt-4">The first print edition of the magazine distributed for our community and across UAE</p>
                    </div>
                    <div className="">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">6</span>ERC-20 RELEASE</h1>
                        <p className="text-white mt-4">10 million utility tokens will be airdropped for our NFTiST holders and general public</p>
                    </div>
                    <div className="">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">7</span>DAO</h1>
                        <p className="text-white mt-4">Our media trust will launch a DAO where all the members will have a say</p>
                    </div>
                    <div className="">
                        <h1 style={{ color: "#affe00" }} className="font-bold text-2xl"><span style={{ color: "#affe00" }} className="text-5xl mr-4 font-light">8</span>METAVERSE</h1>
                        <p className="text-white mt-4">The City of THE NFTiST will be launched. See you in the Metaverse</p>
                    </div>
                </div>
                <img className="absolute -left-36 top-0 w-screen h-[100rem] opacity-80" src={bgg} />
                {/* <img className="w-screen h-[100rem] absolute inset-0 " style={{ transform: 'translateY(18%)', left:'-20px' }} src={bgg2}/> */}
            </div>
        </section>
    )
}

export default Roadmap